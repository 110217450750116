
import { defineComponent, inject } from 'vue';
import AccountingInfoDetails from '@/components/Accounting/AccountingInfo/AccountingInfoDetails.vue';
import AccountingInfoHeader from '@/components/Accounting/AccountingInfo/AccountingInfoHeader.vue';
import MobileFooterActions from '@/components/MobileFooterActions.vue';
import router from '@/router';

export default defineComponent({
    components: {
        AccountingInfoDetails,
        AccountingInfoHeader,
        MobileFooterActions
    },
    props: {
        tab: {
            type: Number,
            required: true
        }
    },
    async setup() {
        const emitter: any = inject('emitter');
        function back() {
            router.back();
        }

        function save() {
            emitter.emit('save-accounting');
        }

        function onTabChanged(index: number) {
            router.replace({ name: 'AccountingInfoEditView', params: { tab: index } });
        }

        return {
            back,
            save,
            onTabChanged
        };
    }
});
